/**
 * CarouselButton element.
 *
 * This class implements the mv-carousel-button element, which is a single
 * carousel navigation button that can be used to slide the carousel by a number
 * of slides specified by the delta attribute.
 *
 * The container attribute can be used to specify a selector for the a container
 * that is an ancestor of both the carousel and this element. It defaults to the
 * parent of this element. An event listener is added to the container for the
 * slide-count-changed event, so that the button can be enabled and disabled
 * automatically.
 *
 * The carousel attribute can be used to specify a selector for the carousel,
 * as a child of the container. It defaults to mv-carousel. This allows the
 * slideBy method on the carousel to be called when the button is clicked.
 */
export default class CarouselButton extends HTMLButtonElement {
  static observedAttributes = [
    'delta',
    'carousel',
    'container',
  ];

  #abortController;

  connectedCallback() {
    this.delta = parseInt(this.getAttribute('delta'), 10);
    this.carousel = this.getAttribute('carousel');
    this.container = this.getAttribute('container');

    this.#abortController = new AbortController();

    this.#container.addEventListener('slide-count-changed', this.#onSideCountChanged.bind(this), {
      signal: this.#abortController.signal,
    });

    this.addEventListener('click', this.#onClick.bind(this), {
      signal: this.#abortController.signal,
    });

    const carousel = this.#carousel;
    if (carousel) {
      const { slideCount, firstVisibleIndex, lastVisibleIndex } = carousel;
      this.#updateState({ slideCount, firstVisibleIndex, lastVisibleIndex });
    }
  }

  disconnectedCallback() {
    this.#abortController.abort();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    switch (name) {
      case 'delta':
        this.delta = parseInt(newValue, 10);
        break;

      case 'carousel': // fallthrough
      case 'container':
        this[name] = newValue;
        break;
    }
  }

  get #container() {
    return this.container ? this.closest(this.container) : this.parentElement;
  }

  get #carousel() {
    const selector = this.carousel ? this.carousel : 'mv-carousel';
    return this.#container?.querySelector(selector);
  }

  #onSideCountChanged(e) {
    this.#updateState(e.detail);
  }

  #updateState({ slideCount, firstVisibleIndex, lastVisibleIndex }) {
    if (!Number.isInteger(this.delta)) {
      this.disabled = true;
    } else if (this.delta > 0) {
      this.disabled = lastVisibleIndex + this.delta >= slideCount;
    } else {
      this.disabled = firstVisibleIndex + this.delta < 0;
    }
  }

  #onClick(e) {
    e.preventDefault();

    if (!this.disabled && Number.isInteger(this.delta)) {
      this.#carousel?.slideBy(this.delta);
    }
  }
}

customElements.define('mv-carousel-button', CarouselButton, { extends: 'button' });
