import { LitElement, css, html } from 'lit';

/**
 * Carousel element.
 *
 * This class implements the mv-carousel element. It is a container for a set of
 * slides. When the slide count or slide visibility changes, a
 * slide-count-changed is dispatched event so that other elements can update
 * their state.
 *
 * The mv-carousel-button element is built to work with this component.
 */
export default class Carousel extends LitElement {
  static properties = {
    slideCount: {
      type: Number,
      property: true,
    },
    visibleCount: {
      type: Number,
      property: true,
    },
    firstVisibleIndex: {
      type: Number,
      property: true,
    },
    lastVisibleIndex: {
      type: Number,
      property: true,
    },
    dragging: {
      type: Boolean,
      attribute: true,
    },
  }

  static styles = css`
    :host[dragging] {
      scroll-snap-type: none;
    }
  `;

  #mutationObserver;

  #intersectionObserver;

  constructor() {
    super();

    this.slideCount = 0;
    this.visibleCount = 0;
    this.#mutationObserver = new MutationObserver(this.#mutationCallback.bind(this));
    this.#intersectionObserver = new IntersectionObserver(this.#intersectionCallback.bind(this), {
      root: this,
      threshold: 1.0,
    });
  }

  connectedCallback() {
    super.connectedCallback();

    this.addEventListener('mousedown', (e) => { this.dragging = true; });
    this.addEventListener('mouseup', (e) => { this.dragging = false; });
    this.addEventListener('mouseleave', (e) => { this.dragging = false; });

    this.#mutationObserver.observe(this, {
      subtree: false,
      childList: true,
      attributes: false,
      characterData: false,
    });

    this.#observeChildren();
    this.#updateCounts();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.#intersectionObserver.disconnect();
    this.#updateCounts();
  }

  #mutationCallback(mutations) {
    for (const mutation of mutations) {
      for (const node of mutations.addedNodes) {
        this.#intersectionObserver.observe(node);
      }

      for (const node of mutations.removedNodes) {
        this.#intersectionObserver.unobserve(node);
      }
    }

    this.#updateCounts();
  }

  #intersectionCallback(entries) {
    for (const entry of entries) {
      entry.target.slideVisible = entry.isIntersecting;
    }

    this.#updateCounts();
  }

  #observeChildren() {
    for (const child of this.children) {
      this.#intersectionObserver.observe(child);
    }
  }

  #updateCounts() {
    let slideCount = 0;
    let visibleCount = 0;
    let firstVisibleIndex = null;
    let lastVisibleIndex = null;
    for (const slide of this.children) {
      if (slide.slideVisible) {
        visibleCount++;

        if (firstVisibleIndex === null) {
          firstVisibleIndex = slideCount;
        }

        lastVisibleIndex = slideCount;
      }

      slideCount++;
    }

    let changed = false;

    if (slideCount !== this.slideCount) {
      this.slideCount = slideCount;
      changed = true;
    }

    if (visibleCount !== this.visibleCount) {
      this.visibleCount = visibleCount;
      changed = true;
    }

    if (firstVisibleIndex !== this.firstVisibleIndex) {
      this.firstVisibleIndex = firstVisibleIndex;
      changed = true;
    }

    if (lastVisibleIndex !== this.lastVisibleIndex) {
      this.lastVisibleIndex = lastVisibleIndex;
      changed = true;
    }

    if (changed) {
      this.dispatchEvent(new CustomEvent('slide-count-changed', {
        detail: {
          slideCount,
          visibleCount,
          firstVisibleIndex,
          lastVisibleIndex,
        },
        bubbles: true,
      }));
    }
  }

  render() {
    return html`
      <slot id="slides"></slot>
    `;
  }

  slideBy(delta) {
    if (!Number.isInteger(delta) || delta === 0) {
      return;
    }

    const index = delta > 0 ? this.lastVisibleIndex + delta : this.firstVisibleIndex + delta;
    if (index < 0 || index >= this.slideCount) {
      return;
    }

    const slide = this.children[index];

    slide.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: delta > 0 ? 'end' : 'start',
    });
  }
}

customElements.define('mv-carousel', Carousel);
